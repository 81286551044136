<template>
    <v-container>
        <v-row class="main-logo-wrapper ml-0 mr-0">
            <v-col>
                <p
                    class="header-description"
                    v-html="mainDescription"
                />
            </v-col>
        </v-row>

        <VueLazyHydration when-idle>
            <v-row class="products-list-wrapper ml-0 mr-0 mt-0">
                <v-col class="col-12 col-md-6 col-lg-6">
                    <TransitionGroup name="fade" mode="out-in">
                        <p key="a" class="product-item__description" v-html="bodyData.solution" />
                        <p
                            v-show="productDescription"
                            key="b"
                            class="product-item__description"
                            v-html="productDescription"
                        />
                    </TransitionGroup>
                </v-col>
                <v-col class="col-12 col-md-6 col-lg-6">
                    <ul class="products-list">
                        <li
                            v-for="product in productHomeList"
                            :id="`${product.link}-wrap`"
                            :key="product.name"
                            class="product-item"
                            :class="{'product-item--active': lastActiveProduct.link === product.link}"
                            @mouseover="updateProductDescription(product)"
                        >
                            <NuxtLink
                                :to="{ name: 'product-item', params: { item: product.link } }"
                                class="product-item__link"
                            >
                                <nuxt-img
                                    :id="product.link"
                                    :src="product.imgPath"
                                    :alt="product.alt"
                                    width="210"
                                    height="210"
                                    class="product-item__ico"
                                    quality="100"
                                    loading="lazy"
                                    format="webp"
                                />
                                <span class="product-item__title">
                                    {{ product.name }}
                                </span>
                                <span class="product-item__tag">
                                    {{ product.tag }}
                                </span>
                            </NuxtLink>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </VueLazyHydration>
    </v-container>
</template>

<script>
import VueLazyHydration from 'vue-lazy-hydration';

import headMixins from '~/mixins/head-mixins';

export default {
    name: 'IndexPage',
    components: { VueLazyHydration },
    mixins: [headMixins],
    async asyncData({ store }) {
        const pageContent = await store.dispatch('fetchPageContent', 'home');

        return {
            headData: pageContent.head,
            bodyData: pageContent.body
        };
    },
    data() {
        return {
            headData: null,
            bodyData: null
        };
    },
    computed: {
        mainDescription() {
            return this.$store.state.sharedData?.general?.why;
        },
        productHomeList() {
            return this.$store.state.products;
        },
        productDescription() {
            if (!this.lastActiveProduct) {
                return null;
            }
            return this.lastActiveProduct.description;
        },
        lastActiveProduct() {
            return this.$store.state.lastActiveProduct || this.productHomeList[0];
        }
    },
    methods: {
        updateProductDescription(product) {
            this.$store.commit('setLastActiveProduct', product);
        }
    }
};
</script>

<style lang="scss" src="./home.scss" />
